<template>
  <div v-if="user" class="main-content">
    <nav class="breadcrumb">
      <ul class="breadcrumb__nav">
        <li class="breadcrumb__nav-item breadcrumb__nav-item--back"><router-link :to="{ name: 'AdminsList' }"><img src="@/assets/images/icons/ico-chev-left--70L.svg"></router-link></li>
        <li class="breadcrumb__nav-item"><router-link :to="{ name: 'AdminsList' }">{{$t('adminedit.breadcrumb')}}</router-link></li>
        <li class="breadcrumb__nav-item">{{$t('adminedit.breadcrumbnavitem')}}</li>
      </ul>
    </nav>
    <header class="titles-header">
      <div class="titles-header__side">
        <h2 class="titles-header__title" >{{$t('adminedit.titleedit')}} : {{user.first_name}} {{user.last_name}}</h2>
        <p class="titles-header__subtitle subtitle"></p>
      </div>

    </header>
    <section class="main-section">
      <form @submit.prevent="updateUser()">
        <div class="form__group">
          <label class="form__label" for="first-name">{{$t('adminedit.labelname')}}</label>
          <input class="form__input" type="text" v-model.trim="user.first_name" id="first-name" required autofocus>
        </div>
        <div class="form__group">
          <label class="form__label" for="last-name">{{$t('adminedit.labellastname')}}</label>
          <input class="form__input" type="text" v-model.trim="user.last_name" id="last-name" required>
        </div>

        <!-- Solucion momentanea más triste del mundo :( -->
        <br><br><br><br>

        <div class="form__actions form__actions--fixed">
          <div class="form__actions-side">
          </div>
          <div class="form__actions-side">
            <button class="btn btn--primary" type="submit">{{$t('adminedit.btnupdate')}}</button>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AdminsEdit',
  data () {
    return {
      user: null
    }
  },
  methods: {
    updateUser () {
      this.$axios.patch(`users/${this.$route.params.userId}`, this.user).then(() => {
        alert(this.$t('adminedit.successmsg'))
        this.$router.push({ name: 'AdminsList' })
      })
    }
  },
  async created () {
    const res = await this.$axios.get(`users/${this.$route.params.userId}`)
    this.user = res.data.user
  }
}
</script>
